<template>
  <div>
    <div v-if="loadingState">
      <loader />
    </div>
    <div v-else>
      <div class="mt-12">
        <v-row>
          <v-col cols="6" class="mx-auto card pa-4">
            
            <v-row>
              <v-col cols="12">
                <errors :errors="errors" />
              </v-col>
              <v-col cols="12">
                <div class="d-flex">
                  <v-text-field
                    v-model="formData.headline"
                    outlined
                    label="Notice Headline"
                  >
                  </v-text-field>
                  <span style="color:red">*</span>
                </div>
              </v-col>
              <v-col cols="12">
                <div class="d-flex">
                  <v-file-input
                    @change="convertFileToBase64"
                    outlined
                    prepend-icon=""
                    append-icon="fa-file-pdf"
                    show-size
                    label="Notice File"
                    v-model="file"
                    accept="application/pdf,
              application/msword,
              application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  >
                  </v-file-input>
                  <span style="color:red">*</span>
                </div>
              </v-col>
              <v-col>
                <v-btn color="success" @click="saveButtonHander">Save</v-btn>
                <!-- <v-btn color="error" @click="reset">Cancel</v-btn> -->
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
      <br />
      <br />
      <div v-if="noticeDataArray.length">
        <data-table
          @deleteHandler="deleteNoticeHandler"
          :tableHeaders="headerDataArray"
          :tableData="noticeDataArray"
          :enableEdit="false"
        />
      </div>
    </div>
  </div>
</template>

<script>
import * as api from "../config/api.js";
import DataTable from "../components/table/table.vue";
export default {
  beforeMount() {
    this.getnoticeSectionIdValue();
  },
  components: {
    DataTable,
  },
  computed: {},
  data() {
    return {
      loadingState: false,
      errors: [],
      formData: {
        headline: "",
        filePath: null,
      },
      initFormData: {
        headline: "",
        filePath: null,
      },
      submenuKey:this.$route.params.id,
      file: null,
      headerDataArray: [
        { text: "Id", value: "id", align: "center" },
        { text: "Name", value: "headline", align: "center" },
        { text: "Creation Date", value: "created_at", align: "center" },
        { text: "", value: "actions", align: "center" },
      ],
      noticeDataArray: [],
    };
  },
  methods: {
    convertFileToBase64() {
      let reader = new FileReader();
      if (this.file) {
        reader.readAsDataURL(this.file);
        reader.addEventListener("load", () => {
          this.formData.filePath = reader.result;
        });
      }
    },
    reset() {
      this.formData = { ...this.initFormData };
      this.file = null;
      this.errors = []
    },
    getnoticeSectionIdValue() {
      let notice = this.$route.params.id;
      this.reset();
      this.submenuKey = notice;
      this.getNoticesDataByNoticeKey();
    },
    saveButtonHander() {
      this.loadingState = true;
      this.$http
        .post(api.NOTICE, { ...this.formData, submenuKey: this.submenuKey })
        .then((res) => {
          alert("saved");
          // console.log("Res==>", res.data);
          this.noticeDataArray.push(res.data);
          this.reset();
        })
        .catch((error) => {
          alert("Error Occurred");
          console.log(" Error=======>", error.response.data);
          if (error.response.status == 403) {
            this.errors = error.response.data; 
          }
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
    getNoticesDataByNoticeKey() {
      this.loadingState = true;
      this.$http
        .get(api.NOTICE + "/" + this.submenuKey)
        .then((res) => {
          // alert("");
          // console.log("Res==>", res.data);
          this.noticeDataArray = res.data;
        })
        .catch((error) => {
          alert("Error Occurred");
          console.log(" Error=======>", error.response.data);
          if (error.response.status == 403) {
            alert();
          }
        })
        .finally(() => {
          this.loadingState = false;
        });
    },

    deleteNoticeHandler(notice) {
      if (confirm("Do you want to delete?")) {
        this.loadingState = true;
        this.$http
          .delete(api.DELETE_NOTICE + "/" + notice.id)
          .then((res) => {
            alert("Deleted");
            console.log("Res==>", res.data);
            this.noticeDataArray = this.noticeDataArray.filter(
              (data) => notice.id != data.id
            );
          })
          .catch((error) => {
            alert("Error Occurred");
            console.log(" Error=======>", error.response.data);
            if (error.response.status == 403) {
              alert();
            }
          })
          .finally(() => {
            this.loadingState = false;
          });
      }
    },
  },
  mounted() {},
  props: {},
  updated() {},
  watch: {
    "$route.params.id"() {
      this.getnoticeSectionIdValue();
    },
  },
};
</script>

<style scoped></style>>
