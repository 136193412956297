<template>
  <!-- <v-card :height="getHeight" > -->
  <v-navigation-drawer
    :height="getHeight"
    color="#e9eef3"
    :right="false"
    width="100%"
    permanent
  >
    <v-list dense nav v-for="(item, index) in items" :key="index">
      <v-list-item v-if="!item.submenus" link @click="menuClickHandler(item)">
        <v-list-item-icon>
          <v-icon> fa-minus-square </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.name }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-group prepend-icon="fa-minus-square" v-else>
        <template v-slot:activator>
          <v-list-item-title>{{ item.name }}</v-list-item-title>
        </template>

        <div v-for="(subItem, index) in item.submenus" :key="index">
          <v-list-item
            link
            v-if="subItem.isActive"
            @click="menuClickHandler(subItem, item)"
            :class="{
              selected: selectedSubmenu && selectedSubmenu.key == subItem.key,
            }"
          >
            <v-list-item-icon class="pl-4">
              <v-icon>fa-minus</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ subItem.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list-group>
    </v-list>

    <template v-slot:append>
      <div>
        <v-btn @click="logout" color="error" block>Logout</v-btn>
      </div>
    </template>
  </v-navigation-drawer>
  <!-- </v-card> -->
</template>

<script>
import * as slug from "./config/slug.js";
import { MENU } from "./config/api.js";
export default {
  beforeMount() {
    this.getAllMenu();
  },
  computed: {
    getHeight() {
      let height = screen.height;
      return height - 145 + "px";
    },
  },
  data() {
    return {
      items: [],
      home: [
        {
          name: "Menu & Submenu",
          submenuType: "home",
          key: "menu",
          isActive: true,
        },
        {
          name: "School Information",
          submenuType: "home",
          key: "school",
          isActive: true,
        },
        {
          name: "Slider",
          submenuType: "home",
          key: "slider",
          isActive: true,
        },
        {
          name: "Speech",
          submenuType: "home",
          key: "speech",
          isActive: true,
        },
        {
          name: "Important Links",
          submenuType: "home",
          key: "links",
          isActive: true,
        },
        {
          name: "Class Videos",
          submenuType: "home",
          key: "class",
          isActive: true,
        },
      ],
      contact:[
        {
          name: "Contact Information",
          submenuType: "contact",
          key: "information",
          isActive: true,
        },
        {
          name: "Messages",
          submenuType: "contact",
          key: "messages",
          isActive: true,
        },
      ],
      right: null,
      selectedSubmenu: null,
    };
  },
  methods: {
    logout() {
      localStorage.removeItem("token");
      window.location.reload();
    },
    getAllMenu() {
      this.$http
        .get(MENU)
        .then((res) => {
          res.data = res.data.map((e) => {
            if (e.key == "home") {
              e.submenus = [...this.home];
            }
            if (e.key == "contact") {
              e.submenus = [...this.contact];
            }
            return e;
          });
          this.items = res.data;
          // console.log(this.items);
        })
        .catch((error) => {
          alert("Error Occurred");
          console.log(" Error=======>", error.response.data);
          if (error.response.status == 403) {
            alert();
          }
        })
        .finally(() => {});
    },

    menuClickHandler(submenu = null, menu = null) {
      if (submenu.submenuType == "co_curriculum") {
        this.$router.push("/curriculum/" + submenu.key);
      }

      if (submenu.submenuType == "details") {
        this.$router.push("/details/" + submenu.key);
      }
      if (submenu.submenuType == "information") {
        this.$router.push("/information/" + submenu.key);
      }

      if (submenu.submenuType == "facility") {
        this.$router.push("/facility/" + submenu.key);
      }

      if (submenu.submenuType == "home") {
        this.$router.push("/home/" + submenu.key);
      }
      
      if (submenu.submenuType == "contact") {
        this.$router.push("/contact/" + submenu.key);
      }

      if (submenu.submenuType == "pdf") {
        this.$router.push("/notice/" + submenu.key);
      }

      if (submenu.submenuType == "table") {
        this.$router.push("/" + submenu.key);
      }

      if (submenu.submenuType == "gallery") {
        this.$router.push("/gallery/" + submenu.key);
      }

      this.selectedSubmenu = submenu;
      // console.log(submenu);
    },
  },
};
</script>

<style scoped>
.selected {
  background-color: rgba(138, 150, 255, 0.438);
}
</style>
